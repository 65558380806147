import React from 'react'
import Layout from '@/layouts/MediaLibraryLayout/MediaLibraryLayout'
import { MediaLibraryVideo } from '../components/MediaLibraryVideo'

const medialibraryvideo = ({ location }) => {
  return (
    <Layout location={location}>
      <MediaLibraryVideo />
    </Layout>
  )
}

export default medialibraryvideo
