import React from 'react'
import Header from '@/components/Header/Header'
import classes from '../MediaLibraryLayout/MediaLibraryLayout.module.css'
import { LoginModal } from '@/modals'

function MediaLibraryLayout({ children, onMain, location }) {
  return (
    <div className={classes.container}>
      <Header onMain={onMain} location={location} />
      {children}

      <LoginModal />
    </div>
  )
}

export default MediaLibraryLayout
