import React, { useEffect, useState } from 'react'
import { getMediaQuery } from '../../api/media'
import classes from './MediaLibraryVideo.module.css'
import { BtnIcon } from '../../images/svg/Arrows'

import { getDatePhoto } from '../../utils/getDate'

export const MediaLibraryVideo = () => {
  const [media, setMedia] = useState({})
  const [indexSelect, setIndexSelect] = useState(0)

  useEffect(() => {
    ;(async () => {
      try {
        const res = await getMediaQuery({
          type: 'video',
          'order[dateTime]': 'desc',
        })
        setMedia(res.data)
      } catch (err) {
        console.error(err)
      }
    })()
  }, [])

  const setNextSlide = index => {
    if (index === media.items.length - 1) {
      setIndexSelect(0)
    } else setIndexSelect(index + 1)
  }
  const setPrevSlide = indexSelect => {
    if (indexSelect === 0) {
      setIndexSelect(media.items.length - 1)
    } else setIndexSelect(indexSelect - 1)
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h2 className={classes.title}>Видео</h2>
        {media?.items?.map((video, index) => {
          if (indexSelect === index) {
            return (
              <div className={classes.video__container}>
                <div className={classes.btn}>
                  <button
                    className={classes.btn__left}
                    onClick={() => setPrevSlide(index)}
                  >
                    <BtnIcon />
                  </button>
                  <iframe
                    className={classes.video}
                    src={video.url}
                    title='YouTube video player'
                    frameborder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowfullscreen
                  ></iframe>
                  <button
                    className={classes.btn__right}
                    onClick={() => setNextSlide(index)}
                  >
                    <BtnIcon className={classes.btn__icon} />
                  </button>
                </div>

                <div className={classes.subtitle}>
                  <div className={classes.subtitle__content}>
                    <span className={classes.name}>{video.name}</span>
                    <span className={classes.date}>
                      {getDatePhoto(video.dateTime)}
                    </span>
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default MediaLibraryVideo
